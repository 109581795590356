
import './App.css';

function App() {
  return (
    <div className="container mar-50">
      <div className="d-flex justify-content-center">
        <h3 className="title align-self-center">c a p p u c c i n o</h3>
      </div>
      <div className="d-flex justify-content-center">
        <span className="subtitle align-self-center"> en desarrollo ☕</span>
      </div>
    </div>
  );
}

export default App;
